<template>
  <Header></Header>
  <section class="bg-white border-b py-8">
    <div class="container max-w-5xl mx-auto m-8">
      <h2 class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
        Informatie voor bezoekers
      </h2>
      <div class="w-full mb-4">
        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-5/6 sm:w-1/2 p-6" style="text-align: start;">

          <h2><strong>Kidsday kinderevenement</strong></h2>
          <h2>Zondag 29 juni 2025</h2>
          <h3><span style="color: #000000;">Van 12:00 – 19:00 uur</span></h3><br />
          <p class="text-gray-600 mb-8">
            <em>Prijzen:</em><br />
            <strong>Entree</strong>Gratis<br />
            <strong>Braskaart</strong>€ 5,00 (leeftijd van 0 tot 12 jaar)<br />(kwijt is helaas kwijt)<br />
            <strong>Consumpties</strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; € 2,75<br /><br />
            <em>Algemene info:</em><br />
            Dit alles zal plaatsvinden op het St. Agathaplein te Boekel (Noord Brabant).<br /><br />
            Op het terrein is het volgende aanwezig:<br />
            - Invalidentoilet<br>
             - Centrale post; Voor problemen, verdwaalde kinderen en alle mogelijke zaken kunt u zich tot hen richten.<br>
              Volg te allen tijde de aanwijzingen van onze vrijwilligers op.<br />
            - Een medische dienst; Op zondag zal deze bestaan uit EHBO’ers en een intern BHV-team.<br />
            <br />
            KidsDay zal niet aansprakelijk zijn voor diefstal, vermissing of beschadigingen opgelopen op het terrein.<br>
              Toegang tot het terrein is voor eigen risico.
            <br /><br />
            Op het terrein kunnen er door onze fotografen foto’s&nbsp;worden&nbsp;gemaakt voor PR-doeleinden.<br>
              Deze mensen zijn te herkennen aan de kleding van “KidsDay”.<br><br />
              Bij het betreden van het terrein gaat u er automatisch mee akkoord dat er foto- en filmopnamen van u gemaakt kunnen worden en geeft u tevens toestemming voor het gebruik van deze foto’s voor PR-doeleinden van Stichting KidsDay.
          </p>
        </div>
        <div class="w-full sm:w-1/2 p-6">
          <img class="w-full md:w-4/5 z-50" src="img/prinses.jpg" />
        </div>
      </div>
    </div>
  </section>
  <Contact></Contact>
  <Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Contact from "@/components/Contact";
import Header from "@/components/Header";

export default {
  name: 'App',
  components: {
    Footer,
    Contact,
    Header
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
