import { createWebHistory, createRouter } from "vue-router";
import Home from "@/pages/Home.vue";
import Bezoekers from "@/pages/Bezoekers.vue";
import Informatie from "@/pages/Informatie.vue";
import Vrijwilligers from "@/pages/Vrijwilligers.vue";
import Sponsoren from "@/pages/Sponsoren.vue";
import Verlanglijstje from "@/pages/Verlanglijstje.vue";
import Disclaimer from "@/pages/Disclaimer.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/informatie",
        name: "Informatie",
        component: Informatie,
    },
    {
        path: "/bezoekers",
        name: "Bezoekers",
        component: Bezoekers,
    },
    {
        path: "/vrijwilligers",
        name: "Vrijwilligers",
        component: Vrijwilligers,
    },
    {
        path: "/sponsoren",
        name: "Sponsoren",
        component: Sponsoren,
    },
    {
        path: "/verlanglijstje",
        name: "Verlanglijstje",
        component: Verlanglijstje,
    },
    {
        path: "/disclaimer",
        name: "Disclaimer",
        component: Disclaimer,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
